<script>
// import Layout from "../../layouts/main";
// import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import appConfig from "@/app.config";

// import { chatData, chatMessagesData, groupData } from "./data";
import { required } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import useNetw from "@useNetw";
import Swal from "sweetalert2";

/**
 * Chat component
 */
export default {
  name: "CategoryUploadComponent",
  props: {
    paramsIdNum: Number,
    paramsIdStr: String,
    urlShow: String,
    paramsShow: Object,
    urlUpload: String,
    urlFullsize: String,
    urlDelete: String,
    paramsDel: Object,
    paramsSending: String,
    paramsFullsize: Object,
  },
  // props: ["category", "booking"],
  page: {
    title: "Chat",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    vueDropzone: vue2Dropzone,
    simplebar,
  },
  data() {
    return {
      //   chatData: chatData,
      //   groupData: groupData,
      //   chatMessagesData: chatMessagesData,
      rowsCategory: [],
      rowsImg: [],
      allImg: "",
      visible: false,
      index: 0,
      categoryId: "",
      paramsDelete: this.paramsDel,
      paramsFull: this.paramsFullsize,
      dropzoneOptions: {
        url: `${appConfig.api.baseURL}${this.urlUpload}`,
        thumbnailWidth: 150,
        maxFilesize: 10,
        uploadMultiple: false,
        paramName: "image",
        init: function () {
          const user = JSON.parse(
            localStorage.getItem(appConfig.storageUserKey)
          );
          this.token = `${user[appConfig.storageTokenKeyName]}`;
          // this.on("sending", function(file, xhr, formData) {
          // formData.append("svId", 4);
          // formData.append("inspId", 1);
          // formData.append("ruleId", 1);
          // console.log(formData);
          // });
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem(appConfig.storageUserKey))[
              appConfig.storageTokenKeyName
            ]
          }`,
        },
      },
      chatData: [
        {
          id: 1,
          image: require("@/assets/images/users/avatar-2.jpg"),
          name: "John Howard",
          message: "Hey! there I'm available",
          time: "02 min",
          status: "online",
        },
        {
          id: 2,
          name: "Galen Rizor",
          message: "I've finished it! See you so",
          time: "10 min",
          status: "online",
        },
        {
          id: 3,
          image: require("@/assets/images/users/avatar-3.jpg"),
          name: "Bernard Spencer",
          message: "This theme is awesome!",
          time: "22 min",
          status: "away",
        },
        {
          id: 4,
          image: require("@/assets/images/users/avatar-4.jpg"),
          name: "Annie Holder",
          message: "Nice to meet you",
          time: "01 hr",
        },
        {
          id: 5,
          name: "Vernon Smith",
          message: "Wow that's great",
          time: "04 Hrs",
          status: "online",
        },
      ],

      groupData: [
        {
          id: 1,
          name: "General",
          member: 5,
          membername: "Alman",
        },
        {
          id: 2,
          name: "Designers",
          icon: "uil-edit-alt",
          member: 7,
          membername: "Smith",
        },
        {
          id: 3,
          name: "Meeting",
          icon: "uil-users-alt",
          member: 12,
          membername: "Christa",
        },
      ],
      chatMessagesData: [
        {
          id: 1,
          name: "John Howard",
          message: "Good morning everyone !",
          time: "10:00",
        },
        {
          id: 2,
          align: "right",
          name: "Marcus",
          message: "Good morning everyone !",
          time: "10:02",
        },
        {
          id: 3,
          name: "Galen Rizo",
          message: "Hello!",
          time: "10:04",
        },
        {
          id: 4,
          name: "Galen Rizo",
          message: "What about our next meeting?",
          time: "10:04",
        },
        {
          id: 5,
          name: "John Howard",
          message: "Next meeting tomorrow 10.00AM",
          time: "10:06",
        },
        {
          id: 6,
          align: "right",
          name: "Marcus",
          message: "Wow that's great",
          time: "10:07",
        },
      ],
      title: "Chat",
      items: [
        {
          text: "Apps",
        },
        {
          text: "Chat",
          active: true,
        },
      ],
      submitted: false,
      form: {
        message: "",
      },
      username: "",
      countImg: "",
      member: null,
      show: false,
      selectedCategory: [],
    };
  },
  validations: {
    form: {
      message: {
        required,
      },
    },
  },
  mounted() {
    // var container1 = document.querySelector(
    //   "#chat-list .simplebar-content-wrapper"
    // );
    // container1.scrollTo({ top: 500, behavior: "smooth" });
    // var container2 = document.querySelector(
    //   "#containerElement .simplebar-content-wrapper"
    // );
    // container2.scrollTo({ top: 500, behavior: "smooth" });
    this.getCategory();
  },
  methods: {
    /**
     * Get the name of user
     */
    saveCategory(category, status) {
      this.show = status;
      this.categoryId = category.categoryId;
      this.rowsImg = category.files;
      this.username = category.categoryNameTh;
      this.countImg = category.files;
      if (this.$refs.image) {
        this.$refs.image.removeAllFiles();
      }
      // console.log(this.$refs.image);
    },
    sendingEvent(file, xhr, formData) {
      formData.append(
        this.paramsSending,
        this.paramsIdNum != undefined
          ? this.paramsIdNum
          : window.atob(this.paramsIdStr)
      );
      formData.append("catId", this.categoryId);
    },
    formatDate(timestamp) {
      var date = new Date(timestamp);
      var dd = "-" + date.getDate();
      var month = "-" + (date.getMonth() + 1);
      var fullyear = date.getFullYear();
      var hours = date.getHours();
      var minutes = "0" + date.getMinutes();
      var seconds = "0" + date.getSeconds();
      var formattedTime =
        fullyear +
        month +
        dd +
        " " +
        hours +
        ":" +
        minutes.substr(-2) +
        ":" +
        seconds.substr(-2);
      return formattedTime;
    },
    getCategory(file, res) {
      if (res) {
        // this.formatDate(res.createdAt);
        // console.log(this.formatDate(res.createdAt));
        res.createdAt = this.formatDate(res.createdAt);
      }

      this.overlayFlag = true;
      useNetw
        .get(this.urlShow, this.paramsShow)

        .then((response) => {
          this.rowsCategory = response.data.category;
          this.rowsImg.push(res);
          // const arr = [];
          // response.data.category.forEach((item) => {
          //   arr.push(item.files);
          //   // console.log(item.files)
          // })
          // this.rowsImg = arr;
          // console.log(arr)
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    // getDataloadImage: function() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .get(this.url, {
    //       params: {
    //         bookingId: this.booking,
    //       },
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       this.category = response.data.category;

    //       // const arr = [];
    //       // response.data.category.forEach((item) => {
    //         // this.rowsImg = item.files
    //         // console.log(item.files)
    //         // arr.push(item.files);
    //       // });

    //       // this.rowsImg = arr;
    //       // console.log(this.rowsImg,'rowsImg')
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.response.data.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    getDataImageFull: function (img) {
      if (img) {
        this.paramsFull.params.fileId = img.fileId;
      }
      this.overlayFlag = true;
      useNetw
        .get(this.urlFullsize, this.paramsFull)
        .then((response) => {
          // console.log(response.data.data);
          // this.allImg = response.data.filePath;

          const arr = [];
          // this.rowsImg.forEach((item) => {
          arr.push(response.data.data.filePath);
          // });
          this.allImg = arr;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    delImg(file, index) {
      this.paramsDelete.data.fileId = file.fileId;
      this.overlayFlag = true; //skeleton true
      useNetw
        .delete(this.urlDelete, this.paramsDelete)
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.success,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.rowsImg.splice(index, 1);
          this.rowsCategory[index].files.splice(index, 1);
          // this.rowsCategory.splice(index, 1);
          // this.alert(response.data.message);
          // this.getBranchProduct();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
              "<br>" +
              err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
    alert(file, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบข้อมูลหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.delImg(file, index);
          }
        });
    },
    chatUsername(name) {
      this.username = name;
      this.usermessage = "Hello";

      this.chatMessagesData = [];
      this.member = null;
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: this.username,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });
    },

    chatGroup(name, memberno, member) {
      this.username = name;
      this.member = memberno;
      this.usermessage = "Hello";
      this.chatMessagesData = [];
      const currentDate = new Date();

      this.chatMessagesData.push({
        name: member,
        message: this.usermessage,
        time: currentDate.getHours() + ":" + currentDate.getMinutes(),
      });
    },

    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const message = this.form.message;
        const currentDate = new Date();
        this.chatMessagesData.push({
          align: "right",
          name: "Marcus",
          message,
          time: currentDate.getHours() + ":" + currentDate.getMinutes(),
        });
        this.handleScroll();
      }
      this.submitted = false;
      this.form = {};
    },
    handleScroll() {
      if (this.$refs.current.$el) {
        setTimeout(() => {
          this.$refs.current.SimpleBar.getScrollElement().scrollTop =
            this.$refs.current.SimpleBar.getScrollElement().scrollHeight + 1500;
        }, 500);
      }
    },
    showImg(index, data) {
      if (this.urlFullsize) {
        this.getDataImageFull(data);
      }
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>

<template>
  <div class="d-lg-flex mb-4">
    <div class="chat-leftsidebar card">
      <!-- <div class="p-3 px-4">
          <div class="media">
            <div class="align-self-center me-3">
              <img
                src="@/assets/images/users/avatar-4.jpg"
                class="avatar-xs rounded-circle"
                alt
              />
            </div>
            <div class="media-body">
              <h5 class="font-size-16 mt-0 mb-1">
                <a href="#" class="text-dark">
                  Marcus
                  <i
                    class="mdi mdi-circle text-success align-middle font-size-10 ms-1"
                  ></i>
                </a>
              </h5>
              <p class="text-muted mb-0">Available</p>
            </div>

            <div>
              <b-dropdown
                variant="white"
                class="chat-noti-dropdown"
                toggle-class="py-0"
                menu-class="dropdown-menu-end"
                right
              >
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-h"></i>
                </template>
                <b-dropdown-item href="#">Profile</b-dropdown-item>
                <b-dropdown-item href="#">Edit</b-dropdown-item>
                <b-dropdown-item href="#">Add Contact</b-dropdown-item>
                <b-dropdown-item href="#">Setting</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div> -->

      <!-- <div class="p-3">
          <div class="search-box chat-search-box">
            <div class="position-relative">
              <input
                type="text"
                class="form-control bg-light border-light rounded"
                placeholder="Search..."
              />
              <i class="uil uil-search search-icon"></i>
            </div>
          </div>
        </div> -->

      <div class="pb-3">
        <simplebar style="max-height: 470px" id="chat-list">
          <!-- {{booking}} -->
          <div class="p-4 border-top">
            <div>
              <!-- <div class="float-end">
                  <a href class="text-primary">
                    <i class="mdi mdi-plus"></i> New Group
                  </a>
                </div> -->
              <h5 class="font-size-16 mb-3">
                <i class="uil uil-users-alt me-1"></i> ประเภทหมวดหมู่
              </h5>

              <ul class="list-unstyled chat-list group-list">
                <li
                  v-for="(group, index) in rowsCategory"
                  :key="index"
                  @click="
                    chatGroup(
                      group.categoryNameTh,
                      group.member,
                      group.membername
                    )
                  "
                  :class="{ active: username == group.categoryNameTh }"
                >
                  <a @click="saveCategory(group, true)">
                    <div class="media align-items-center">
                      <!-- <div class="avatar-xs me-3">
                        <span
                          v-if="!group.icon"
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                          >C</span
                        >
                        <span
                          v-if="group.icon"
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          <i :class="`uil ${group.icon}`"></i>
                        </span>
                      </div> -->

                      <div class="media-body">
                        <h5 class="font-size-14 mb-0">
                          {{ group.categoryNameTh }}
                          <span
                            class="badge bg-danger float-end"
                            v-if="group.files.length"
                            >{{ group.files.length }}</span
                          >
                        </h5>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <!-- <div class="p-4 border-top">
              <div>
                <div class="float-end">
                  <a href class="text-primary">
                    <i class="mdi mdi-plus"></i> New Contact
                  </a>
                </div>
                <h5 class="font-size-16 mb-3">
                  <i class="uil uil-user me-1"></i> Contacts
                </h5>

                <ul class="list-unstyled chat-list">
                  <li
                    v-for="data of chatData"
                    :key="data.id"
                    @click="chatUsername(data.name)"
                    :class="{ active: username == data.name }"
                  >
                    <a href="#">
                      <div class="media">
                        <div
                          class="user-img align-self-center me-3"
                          :class="{
                            away: data.status === 'away',
                            online: data.status === 'online',
                          }"
                        >
                          <img
                            v-if="data.image"
                            :src="data.image"
                            class="rounded-circle avatar-xs"
                            alt
                          />
                          <div
                            v-if="!data.image"
                            class="avatar-xs align-self-center"
                          >
                            <span
                              class="avatar-title rounded-circle bg-soft-primary text-primary"
                              >{{ data.name.charAt(0) }}</span
                            >
                          </div>
                          <span class="user-status"></span>
                        </div>

                        <div class="media-body overflow-hidden">
                          <h5 class="text-truncate font-size-14 mb-1">
                            {{ data.name }}
                          </h5>
                          <p class="text-truncate mb-0">{{ data.message }}</p>
                        </div>
                        <div class="font-size-11">{{ data.time }}</div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div> -->
        </simplebar>
      </div>
    </div>
    <!-- end chat-leftsidebar -->

    <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
      <div class="card">
        <div class="p-3 px-lg-4 border-bottom">
          <div class="row">
            <div class="col-md-8 col-6">
              <h5 class="font-size-16 mb-1">
                <a class="text-dark">
                  {{ username }}
                  <span class="badge bg-danger" v-if="countImg.length">{{
                    countImg.length
                  }}</span>
                </a>
              </h5>
              <!-- <p class="text-muted text-truncate mb-0" v-if="member !== null">
                <i class="uil uil-users-alt me-1"></i>
                {{ member !== null ? member : "" }} Members
              </p> -->
            </div>
            <div class="col-md-4 col-6">
              <ul class="list-inline user-chat-nav text-end mb-0">
                <!-- <li class="list-inline-item">
                  <b-dropdown
                    right
                    menu-class="dropdown-menu-md dropdown-menu-end"
                    variant="white"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-search"></i>
                    </template>

                    <form class="p-2">
                      <div>
                        <input
                          type="text"
                          class="form-control rounded"
                          placeholder="Search..."
                        />
                      </div>
                    </form>
                  </b-dropdown>
                </li> -->

                <!-- <li class="list-inline-item">
                  <b-dropdown right toggle-class="nav-btn" variant="white">
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <a class="dropdown-item" href="#">Profile</a>
                    <a class="dropdown-item" href="#">Archive</a>
                    <a class="dropdown-item" href="#">Muted</a>
                    <a class="dropdown-item" href="#">Delete</a>
                  </b-dropdown>
                </li> -->
              </ul>
            </div>
          </div>
        </div>

        <div class="px-lg-2 chat-users">
          <div class="chat-conversation p-3">
            <simplebar
              style="max-height: 455px"
              id="containerElement"
              ref="current"
              v-if="show == true"
            >
              <vue-dropzone
                id="image"
                ref="image"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                v-on:vdropzone-success="getCategory"
                v-on:vdropzone-sending="sendingEvent"
                v-if="urlUpload"
              >
                <div class="dropzone-custom-content">
                  <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                  <h4>อัพโหลดไฟล์ภาพ</h4>
                </div>
              </vue-dropzone>
              <div class="popup-gallery">
                <div
                  v-for="(data, index) in rowsImg"
                  :key="'img' + index"
                  class="float-start m-2"
                >
                  <div class="card shadow">
                    <img
                      :src="data.thumbnailPath"
                      class="d-block"
                      width="150"
                      height="150"
                      @click="() => showImg(index, data)"
                    />
                    <span class="badge bg-soft-info m-2"
                      ><div class="m-1">โดย: {{ data.createdBy }}</div>
                      {{ data.createdAt }}</span
                    >
                    <a
                      href="javascript:void(0);"
                      class="text-danger text-center"
                      v-b-tooltip.hover
                      title="Delete"
                      v-if="urlDelete"
                      @click="alert(data, index)"
                    >
                      <i class="uil uil-trash font-size-14"></i>
                    </a>
                  </div>
                </div>
                <vue-easy-lightbox
                  :visible="visible"
                  :imgs="this.allImg"
                  :index="index"
                  @hide="handleHide"
                ></vue-easy-lightbox>
              </div>
              <!-- <ul class="list-unstyled mb-0">
                  <li class="chat-day-title">
                    <div class="title">Today</div>
                  </li>
                  <li
                    v-for="data of chatMessagesData"
                    :key="data.id"
                    :class="{ right: `${data.align}` === 'right' }"
                  >
                    <div class="conversation-list">
                      <div class="ctext-wrap">
                        <div class="ctext-wrap-content">
                          <h5 class="font-size-14 conversation-name">
                            <a href="#" class="text-dark">{{ data.name }}</a>
                            <span
                              class="d-inline-block font-size-12 text-muted ms-2"
                              >{{ data.time }}</span
                            >
                          </h5>
                          <p class="mb-0">{{ data.message }}</p>
                        </div>
                        <b-dropdown class="align-self-start" variant="white">
                          <template v-slot:button-content>
                            <i class="uil uil-ellipsis-v"></i>
                          </template>
                          <a class="dropdown-item" href="#">Copy</a>
                          <a class="dropdown-item" href="#">Save</a>
                          <a class="dropdown-item" href="#">Forward</a>
                          <a class="dropdown-item" href="#">Delete</a>
                        </b-dropdown>
                      </div>
                    </div>
                  </li>
                </ul> -->
            </simplebar>
          </div>
        </div>

        <!-- <div class="p-3 chat-input-section">
            <form @submit.prevent="formSubmit" class="row">
              <div class="col">
                <div class="position-relative">
                  <input
                    type="text"
                    v-model="form.message"
                    class="form-control chat-input rounded"
                    placeholder="Enter Message..."
                    :class="{
                      'is-invalid': submitted && $v.form.message.$error,
                    }"
                  />
                  <div
                    v-if="submitted && $v.form.message.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.message.required"
                      >This value is required.</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="submit"
                  class="btn btn-primary chat-send w-md waves-effect waves-light"
                >
                  <span class="d-none d-sm-inline-block me-2">Send</span>
                  <i class="mdi mdi-send float-end"></i>
                </button>
              </div>
            </form>
          </div> -->
      </div>
    </div>
  </div>
  <!-- End d-lg-flex  -->
</template>
